import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import ModularBlocks from '../../components/ModularBlocks';
import ContactForm from '../../components/ContactForm';
import CompanyReviews from '../../components/CompanyReviews';

const ProjectPageTemplate = ({
  data: {
    datoCmsProject: { seoMetaTags, title, companyLogo, modularBlocks },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <PageHeader
        overline="Projects"
        heading={title}
        text=""
        logo={companyLogo}
        breadcrumbs={[
          { slug: 'plants-for-business', text: 'Plants for Business' },
        ]}
      />
      <ModularBlocks items={modularBlocks} isProjectPage={true} />
    </main>
    <ContactForm />
    <CompanyReviews />
  </Layout>
);

export const ProjectPageTemplateQuery = graphql`
  query ProjectPageTemplateQuery($id: String!) {
    datoCmsProject(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      companyLogo {
        url
        alt
      }
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...HighlightedTextModularBlockFragment
        ...ImageContentModularBlockFragment
        ...ImageGalleryModularBlockFragment
        ...InternalVideoModularBlockFragment
      }
    }
  }
`;

export default ProjectPageTemplate;
